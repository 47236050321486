jQuery(document).ready(function($){

    jQuery(".menu-scroll > a").click(function() {
        var target = $(this).attr('href').split('#');
        var $el = $('#' + target[1]);

        if ($el.length == 0)
            return true;

        var offset = $el.offset().top;
        $('html, body').animate({
            scrollTop: offset,
        }, 800);

        return false;
    });


});
