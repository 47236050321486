jQuery(document).ready(function($){



	$('#header_slider').slick({
		dots: false,
		speed: 1000,
		infinite: true,
		autoplay: false,
		fade: true,
  		cssEase: 'ease-out',
  		arrows: true,
  		prevArrow: '<button type="button" class="slick-prev">&nbsp;</button>',
  		nextArrow: '<button type="button" class="slick-next">&nbsp;</button>',
	});


});
